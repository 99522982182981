import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  color: white;
`;

const TitleName = styled.h2`
  padding: 1rem;
  border-bottom: 1px solid #999999;
  font-size: 2rem;
  font-weight: 700;
`;

const VenuePage = () => {
  return (
    <Layout title="Venue">
      <SEO pagetitle="Venue" pagedesc="対局場について" />
      <ContentWrapper>
        <Main>
          <TitleName>竜王戦</TitleName>
          <TitleName>名人戦</TitleName>
          <TitleName>王位戦</TitleName>
          <TitleName>王座戦</TitleName>
          <TitleName>棋王戦</TitleName>
          <TitleName>叡王戦</TitleName>
          <TitleName>王将戦</TitleName>
          <TitleName>棋聖戦</TitleName>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export default VenuePage;
