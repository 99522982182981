import * as React from "react";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import styled from "styled-components";
import GlobalStyle from "../styles/GlobalStyle";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

const StyledMain = styled.main``;

const Layout = ({ children, title }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Hero title={title} />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
