import * as React from "react";
import styled from "styled-components";
import ContentWrapper from "./ContentWrapper";

const HeroBody = styled.div`
  background: #61dafb;
  color: white;
  font-size: 6rem;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 3rem;
    font-weight: 700;
  }
`;

const Hero = ({ title }) => {
  return (
    <HeroBody>
      <ContentWrapper>
        <h1>{title}</h1>
      </ContentWrapper>
    </HeroBody>
  );
};

export default Hero;
